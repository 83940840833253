import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import styles from './styles.module.scss';

const SERVER_API_ADDRESS = 'https://dorebu-backend.tk';
const LOCALHOST_API_ADDRESS = 'http://0.0.0.0:5000';

const GENERATE_REBUS_URL = '/generate_rebus';
const CONVERT_TO_LIAPHON_URL = '/convert_to_liaphon';
const CONVERT_TO_LIAPHON_RAW_URL = '/convert_to_liaphon_raw';

const DebugContainer = () => {
  const [currentAdress, setCurrentAdress] = useState(SERVER_API_ADDRESS);
  const [inputText, setInputText] = useState('');
  const [base64Image, setBase64Image] = useState();
  const [resultFromRequest, setResultFromRequest] = useState('');

  const generateRebus = async () => {
    try {
      const response = await fetch(currentAdress + GENERATE_REBUS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: inputText,
          is_debug: true,
          strokeColor: '#FFFF00',
          backgroundColor: '#000066',
        }),
      });

      if (response.status === 200) {
        setBase64Image(URL.createObjectURL(await response.blob()));

        setTimeout(() => {
          setResultFromRequest('Génération terminée');
        }, 750);
      } else if (response.status === 500) {
        setResultFromRequest('Erreur : impossible de générer ce rébus');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const convertToLiaPhon = async () => {
    try {
      const response = await fetch(currentAdress + CONVERT_TO_LIAPHON_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText, raw: false }),
      });

      displayResultFromLiaPhonContent(await response.text());
    } catch (err) {
      console.log(err);
    }
  };

  const convertToLiaPhonRaw = async () => {
    try {
      const response = await fetch(currentAdress + CONVERT_TO_LIAPHON_RAW_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: inputText,
          raw: true,
        }),
      });

      displayResultFromLiaPhonContent(await response.text());
    } catch (err) {
      console.log(err);
    }
  };

  const displayResultFromLiaPhonContent = (responseText) => {
    responseText = responseText.replace(/\s/g, '');
    let formattedResponseText = '[';
    for (let i = 0; i < responseText.length; i++) {
      if (i !== 0 && i % 2 === 0 && i + 1 !== responseText.length)
        formattedResponseText += ', ';
      formattedResponseText += responseText[i];
    }
    formattedResponseText += ']';
    setResultFromRequest(formattedResponseText);
  };

  const onConvertToLiaphonButtonClicked = () => {
    setResultFromRequest('Conversion en cours...');
    convertToLiaPhon();
  };

  const onConvertToLiaphonButtonClickedRaw = () => {
    setResultFromRequest('Conversion en cours...');
    convertToLiaPhonRaw();
  };

  const onGenerateRebusButtonClicked = () => {
    if (inputText !== '') {
      setResultFromRequest('Génération du rébus...');
      generateRebus();
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Dorebu - Debug</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.radioButtons}>
          <input
            type="radio"
            value="Serveur prod"
            checked={currentAdress === SERVER_API_ADDRESS}
            onChange={() => setCurrentAdress(SERVER_API_ADDRESS)}
          />
          Serveur prod
          <input
            type="radio"
            value="Serveur local"
            checked={currentAdress === LOCALHOST_API_ADDRESS}
            onChange={() => setCurrentAdress(LOCALHOST_API_ADDRESS)}
          />
          Serveur local
        </div>
        <textarea
          onChange={(event) => setInputText(event.target.value)}
        ></textarea>
        <h3>Conversion vers lia_phon</h3>
        <div className={styles.buttonsList}>
          <button onClick={() => onConvertToLiaphonButtonClickedRaw()}>
            Version brute
          </button>
          <button onClick={() => onConvertToLiaphonButtonClicked()}>
            Version avec remplacements et exceptions
          </button>
        </div>
        <h3>Génération rébus</h3>
        <div className={styles.buttonsList}>
          <button onClick={() => onGenerateRebusButtonClicked('score')}>
            Méthode de score
          </button>
        </div>

        <div>
          {resultFromRequest !== '' && <p>{resultFromRequest}</p>}
          {base64Image !== undefined && (
            <img src={base64Image} alt="ImageRebus" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DebugContainer;
