import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

const MyCirclePicker = ({
  colors,
  onColorSelected,
  isVisible,
  selected,
  type,
}) => {
  const onColorButtonClicked = (color) => {
    onColorSelected(color, type);
  };

  return (
    <div className={styles.container}>
      {colors.map((color) => (
        <div
          key={color}
          style={{
            backgroundColor: color,
            visibility: isVisible === true ? 'visible' : 'hidden',
          }}
          className={classnames(styles.colorButton, {
            [styles.selected]: color === selected,
          })}
          onClick={() => onColorButtonClicked(color)}
        />
      ))}
    </div>
  );
};

export default MyCirclePicker;
