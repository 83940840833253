import React from 'react';

import styles from './styles.module.scss';
import ActionsButtonsContainer from 'components/ActionsButtonsContainer/component';

const BottomMenuContainer = ({
  isVisible,
  areActionButtonsVisible,
  onDownloadButtonClicked,
  onColorsButtonClicked,
}) => {
  return (
    isVisible && (
      <div className={styles.container}>
        <div className={styles.buttonsContainer}>
          <button
            className={styles.colorsButton}
            onClick={() => onColorsButtonClicked()}
            type="button"
          >
            Couleurs
          </button>
          <ActionsButtonsContainer
            isVisible={areActionButtonsVisible}
            onDownloadButtonClicked={onDownloadButtonClicked}
          />
        </div>
      </div>
    )
  );
};

export default BottomMenuContainer;
