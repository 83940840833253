import React, { useState, useContext } from 'react';

import MyCirclePicker from './MyCirclePicker';
import { ColorsContext } from 'context/ColorsContext';

import styles from './styles.module.scss';

const STROKE_TYPE = 'stroke';
const BACKGROUND_TYPE = 'background';

const ALL_COLORS = [
  '#000000',
  '#006600',
  '#0A526B',
  '#0000CC',
  '#000099',
  '#000066',
  '#FFFFFF',
  '#F7E9E4',
  '#EEBBB0',
  '#CC7D6D',
  '#C73A29',
  '#FFFF00',
];

const ColorPickers = ({ onChange }) => {
  const [currentType, setCurrentType] = useState('');
  const [pickerStrokeVisible, setPickerStrokeVisible] = useState(false);
  const [pickerBackgroundVisible, setPickerBackgroundVisible] = useState(false);

  const {
    strokeColor,
    setStrokeColor,
    backgroundColor,
    setBackgroundColor,
  } = useContext(ColorsContext);

  const onColorPickerButtonClicked = (type) => {
    setCurrentType(type);
    if (type === STROKE_TYPE) {
      setPickerStrokeVisible(!pickerStrokeVisible);
      setPickerBackgroundVisible(false);
    } else if (type === BACKGROUND_TYPE) {
      setPickerBackgroundVisible(!pickerBackgroundVisible);
      setPickerStrokeVisible(false);
    }
  };

  const onColorChoosen = (color) => {
    if (currentType === STROKE_TYPE) {
      setStrokeColor(color);
      setPickerStrokeVisible(false);
      onChange(color, backgroundColor);
    } else if (currentType === BACKGROUND_TYPE) {
      setBackgroundColor(color);
      setPickerBackgroundVisible(false);
      onChange(strokeColor, color);
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Couleurs</h3>
      <div className={styles.colorSelectorContainer}>
        <MyCirclePicker
          colors={ALL_COLORS}
          onColorSelected={onColorChoosen}
          isVisible={pickerBackgroundVisible}
          selected={backgroundColor}
        />
        <div
          className={styles.colorPickerButton}
          style={{ backgroundColor: backgroundColor }}
          onClick={() => onColorPickerButtonClicked(BACKGROUND_TYPE)}
        ></div>
      </div>
      <div className={styles.colorSelectorContainer}>
        <MyCirclePicker
          colors={ALL_COLORS}
          onColorSelected={onColorChoosen}
          isVisible={pickerStrokeVisible}
          selected={strokeColor}
        />
        <div
          className={styles.colorPickerButton}
          style={{ backgroundColor: strokeColor }}
          onClick={() => onColorPickerButtonClicked(STROKE_TYPE)}
        ></div>
      </div>
    </div>
  );
};

export default ColorPickers;
