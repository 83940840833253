import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import styles from './styles.module.scss';

const SERVER_API_ADDRESS = 'https://dorebu-backend.tk';
const LOCALHOST_API_ADDRESS = 'http://0.0.0.0:5000';

const GET_COUNT_SYLLABLES_URL = '/get_count_phonemes';

const StatsContainer = () => {
  const [currentAdress, setCurrentAdress] = useState(LOCALHOST_API_ADDRESS);
  const [inputText, setInputText] = useState('');
  const [resultFromRequest, setResultFromRequest] = useState([]);
  const [nbrPhonemes, setNbrPhonemes] = useState(2);

  const generateStats = async () => {
    try {
      const response = await fetch(currentAdress + GET_COUNT_SYLLABLES_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText, nbrPhonemes: nbrPhonemes }),
      });
      const responseJson = await response.json();
      setResultFromRequest(responseJson.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onGenerateStatsClicked = () => {
    generateStats();
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Dorebu stats</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.radioButtons}>
          <input
            type="radio"
            value="Serveur prod"
            checked={currentAdress === SERVER_API_ADDRESS}
            onChange={() => setCurrentAdress(SERVER_API_ADDRESS)}
          />
          Serveur prod
          <input
            type="radio"
            value="Serveur local"
            checked={currentAdress === LOCALHOST_API_ADDRESS}
            onChange={() => setCurrentAdress(LOCALHOST_API_ADDRESS)}
          />
          Serveur local
        </div>
        <span>Texte</span>
        <textarea
          onChange={(event) => setInputText(event.target.value)}
        ></textarea>
        <div>
          <p>Nombre de syllables</p>
          <select
            value={nbrPhonemes}
            onChange={(event) => setNbrPhonemes(event.target.value)}
          >
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
          </select>
        </div>
        <button onClick={() => onGenerateStatsClicked()}>Générer stats</button>
        <div>
          {resultFromRequest &&
            resultFromRequest.map((result) => (
              <span key={result}>
                {result} <br />
              </span>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StatsContainer;
