import React, { useEffect, useRef } from 'react';
import BarLoader from 'react-spinners/BarLoader';

import ArrowAnimMp4 from 'videos/arrow_anim.mp4';
import ArrowAnimWebm from 'videos/arrow_anim.webm';

import styles from './styles.module.scss';

const GeneratedRebusContainer = ({
  base64Image,
  isLoading,
  isAnimationFinished,
  backgroundColor,
  onClick,
}) => {
  const video = useRef(null);

  function renderElement() {
    if (isLoading) {
      // Loading animation
      return (
        <div
          className={styles.loadingContainer}
          style={{ backgroundColor: backgroundColor }}
        >
          <BarLoader
            size={150}
            color={backgroundColor === '#FFFF00' ? '#FFFFFF' : '#FFFF00'}
            loading={true}
          />
        </div>
      );
    } else if (base64Image === undefined) {
      // Video with the arrow
      return (
        <video
          className={styles.video}
          ref={video}
          playsInline
          muted
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={ArrowAnimMp4} type="video/mp4" />
          <source src={ArrowAnimWebm} type="video/webm" />
        </video>
      );
    } else {
      return (
        <img
          className={styles.generatedRebusImage}
          src={base64Image}
          alt="ImageRebus"
          onClick={() => onClick && onClick()}
        />
      );
    }
  }

  useEffect(() => {
    if (isAnimationFinished === true) video.current.play();
  }, [isAnimationFinished]);
  return <div className={styles.container}>{renderElement()}</div>;
};

export default GeneratedRebusContainer;
