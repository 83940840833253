import React from 'react';

import HomeVideoAnimationMp4 from 'videos/home_anim.mp4';
import HomeVideoAnimationWebm from 'videos/home_anim.webm';

import styles from './styles.module.scss';

const HomepageAnimation = () => {
  return (
    <div className={styles.container}>
      <video className={styles.video} autoPlay playsInline muted>
        <source src={HomeVideoAnimationMp4} type="video/mp4" />
        <source src={HomeVideoAnimationWebm} type="video/webm" />
      </video>
      <h1 className={styles.title}>
        {'La fabrique à belles devinettes'.toUpperCase()}
      </h1>
    </div>
  );
};

export default HomepageAnimation;
