import React, { useState, createContext, useMemo } from 'react';

const ColorsContext = createContext();

const ColorsContextProvider = (props) => {
  const [strokeColor, setStrokeColor] = useState('#FFFF00');
  const [backgroundColor, setBackgroundColor] = useState('#000066');

  const contextValues = useMemo(
    () => ({
      strokeColor,
      setStrokeColor,
      backgroundColor,
      setBackgroundColor,
    }),
    [strokeColor, setStrokeColor, backgroundColor, setBackgroundColor]
  );

  return (
    <ColorsContext.Provider value={contextValues}>
      {props.children}
    </ColorsContext.Provider>
  );
};

export { ColorsContextProvider, ColorsContext };
