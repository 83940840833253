import React, { useState, useRef } from 'react';

import ClickableIcon from 'components/ClickableIcon';

import styles from './styles.module.scss';

const DURATION_REGENERATION_TIMEOUT = 420;

const InputContainer = ({
  onGenerateRebusButtonClicked,
  inputText,
  setInputText,
  onFocusStart,
  onFocusEnd,
}) => {
  const [generationTimeout, setGenerationTimeout] = useState(null);

  const inputEl = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputText !== '') {
      onGenerateRebusButtonClicked(inputText);
      inputEl.current.blur();
    }
  };

  const handleChange = (event) => {
    let eventTargetValue = event.target.value;

    setInputText(eventTargetValue);

    if (generationTimeout != null) {
      clearTimeout(generationTimeout);
    }
    const timeout = setTimeout(() => {
      onGenerateRebusButtonClicked(eventTargetValue);
      setGenerationTimeout(null);
    }, DURATION_REGENERATION_TIMEOUT);

    setGenerationTimeout(timeout);
  };

  return (
    <div className={styles.container}>
      <input
        ref={inputEl}
        type="text"
        placeholder="Entrez votre texte"
        onKeyPress={handleKeyDown}
        onChange={handleChange}
        onFocus={() => onFocusStart && onFocusStart()}
        onBlur={() => onFocusEnd && onFocusEnd()}
      />
      <div className={styles.generateButton}>
        <ClickableIcon
          parentFolder={'magic_wand'}
          onClick={() => onGenerateRebusButtonClicked(inputText)}
        />
      </div>
    </div>
  );
};

export default InputContainer;
