import React, { useContext, useEffect } from 'react';

import MyCirclePicker from 'components/ColorPickers/MyCirclePicker';
import { ColorsContext } from 'context/ColorsContext';

import styles from './styles.module.scss';

import QuitImage from 'images/quit.png';

const STROKE_TYPE = 'stroke';
const BACKGROUND_TYPE = 'background';

const ALL_COLORS = [
  '#000000',
  '#006600',
  '#0A526B',
  '#0000CC',
  '#000099',
  '#000066',
  '#FFFFFF',
  '#F7E9E4',
  '#EEBBB0',
  '#CC7D6D',
  '#C73A29',
  '#FFFF00',
];

const ColorPickersMobile = ({ onChange, isVisible, setVisible }) => {
  const {
    strokeColor,
    setStrokeColor,
    backgroundColor,
    setBackgroundColor,
  } = useContext(ColorsContext);

  const onCloseButtonClicked = () => {
    setVisible(false);
  };

  const onColorChoosen = (color, type) => {
    if (type === STROKE_TYPE) {
      setStrokeColor(color);
      onChange(color, backgroundColor);
    } else if (type === BACKGROUND_TYPE) {
      setBackgroundColor(color);
      onChange(strokeColor, color);
    }
  };

  const clickOutsideOfContent = () => {
    setVisible(false);
  };

  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'unset';
  }, [isVisible]);

  return (
    <div
      className={styles.container}
      style={{ display: isVisible ? 'block' : 'none' }}
      onClick={() => clickOutsideOfContent()}
    >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div
          className={styles.closeButton}
          onClick={() => onCloseButtonClicked()}
        >
          <img src={QuitImage} alt="quit" />
        </div>
        <span className={styles.title}>Fond</span>
        <MyCirclePicker
          colors={ALL_COLORS}
          onColorSelected={onColorChoosen}
          type={BACKGROUND_TYPE}
          isVisible={true}
          selected={backgroundColor}
        />
        <br />
        <span className={styles.title}>Rébus</span>
        <MyCirclePicker
          colors={ALL_COLORS}
          onColorSelected={onColorChoosen}
          type={STROKE_TYPE}
          isVisible={true}
          selected={strokeColor}
        />
      </div>
    </div>
  );
};

export default ColorPickersMobile;
