import React from 'react';

import styles from './styles.module.scss';

const TextBlock = ({ blockText, isVisible, marginTop }) => {
  return (
    <div
      className={styles.container}
      style={{
        visibility: isVisible ? 'visible' : 'hidden',
        marginTop: marginTop,
      }}
    >
      <h2 className={styles.title}>{blockText.title}</h2>
      <div
        className={styles.textContainer}
        dangerouslySetInnerHTML={{ __html: blockText.text }}
      ></div>
    </div>
  );
};

export default TextBlock;
