import React from 'react';

import PreviousImage from 'images/back.png';

import styles from './styles.module.scss';

const TextBlockPage = ({ blockText, isVisible, setVisible }) => {
  const onPreviousButtonClicked = () => {
    setVisible(false);
  };
  return (
    <div
      className={styles.container}
      style={{
        visibility: isVisible ? 'visible' : 'hidden',
      }}
    >
      <div className={styles.titleContainer}>
        <div
          type="button"
          className={styles.previousButton}
          onClick={() => onPreviousButtonClicked()}
        >
          <img src={PreviousImage} alt="previous" />
        </div>
        <h2 className={styles.title}>{blockText.title}</h2>
      </div>
      <div
        className={styles.textContainer}
        dangerouslySetInnerHTML={{ __html: blockText.text }}
      ></div>
    </div>
  );
};

export default TextBlockPage;
