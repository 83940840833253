import React, { useState, useEffect, useRef } from 'react';

const NORMAL = 0;
const HOVERED = 1;
const CLICKED = 2;

const ClickableIcon = ({
  parentFolder,
  textType,
  currentTextType,
  onClick,
}) => {
  const [currentState, setCurrentState] = useState(NORMAL);
  const [isOn, setOn] = useState(false);
  const stateRef = useRef(currentState);

  const [currentImage, setCurrentImage] = useState('');

  const onButtonClicked = () => {
    setCurrentState(CLICKED);
    onClick();
  };

  useEffect(() => {
    if (currentTextType !== textType) {
      setOn(false);
      setCurrentState(NORMAL);
    }
  }, [currentTextType, textType]);

  useEffect(() => {
    stateRef.current = currentState;
    switch (currentState) {
      case NORMAL:
        setOn(false);
        setCurrentImage(
          require(`../../images/icons/${parentFolder}/normal.png`)
        );
        break;
      case HOVERED:
        setCurrentImage(
          require(`../../images/icons/${parentFolder}/hovered.png`)
        );
        break;
      case CLICKED:
        setOn(true);
        setCurrentImage(
          require(`../../images/icons/${parentFolder}/clicked.png`)
        );
        break;
      default:
        break;
    }
  }, [currentState, parentFolder]);

  return (
    <img
      src={currentImage}
      alt="icon"
      onClick={() => onButtonClicked()}
      onMouseEnter={() => setCurrentState(HOVERED)}
      onMouseLeave={() =>
        isOn ? setCurrentState(CLICKED) : setCurrentState(NORMAL)
      }
    />
  );
};

export default ClickableIcon;
