import React, { useState, useEffect, useContext } from 'react';

import { TextBlockContext } from 'context/TextBlockContext';

import ColorPickers from 'components/ColorPickers';
import TextBlock from 'components/TextBlock';
import ClickableIcon from 'components/ClickableIcon';

import HelpBlockText from 'text/help.js';

import styles from './styles.module.scss';

const DesktopLeftContainer = ({ onChangeColor }) => {
  const [isTextBlockVisible, setTextBlockVisible] = useState(false);

  const { openedTextBlock, setOpenedTextBlock } = useContext(TextBlockContext);

  useEffect(() => {
    if (openedTextBlock !== 'help' && isTextBlockVisible)
      setTextBlockVisible(false);
  }, [openedTextBlock]); // eslint-disable-line

  const helpIconClicked = () => {
    setTextBlockVisible(!isTextBlockVisible);

    setOpenedTextBlock('help');
  };

  return (
    <div className={styles.container}>
      <ColorPickers onChange={onChangeColor} />
      <div className={styles.helpContainer}>
        <TextBlock blockText={HelpBlockText} isVisible={isTextBlockVisible} />
        <div className={styles.lightBulbIcon}>
          <ClickableIcon
            parentFolder={'lightbulb'}
            onClick={() => helpIconClicked()}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopLeftContainer;
