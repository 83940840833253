import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import HomepageAnimation from 'components/HomepageAnimation';

import styles from './styles.module.scss';
import MainContainer from 'components/MainContainer/component';

const DURATION_ANIMATION = 3600;

const HomeContainer = () => {
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [isFullAnimationFinished, setFullAnimationFinished] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVideoFinished(true);
      setTimeout(() => {
        setFullAnimationFinished(true);
      }, 650);
    }, DURATION_ANIMATION);
  }, [setIsVideoFinished]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Dorébu • La fabrique à belles devinettes</title>
      </Helmet>
      <div className={styles.container}>
        <div
          className={classnames(styles.videoAnimation, {
            [styles.fadeOut]: isVideoFinished === true,
            [styles.hidden]: isFullAnimationFinished === true,
          })}
        >
          <HomepageAnimation />
        </div>
        <div
          className={classnames(styles.generator, {
            [styles.fadeIn]: isVideoFinished === true,
          })}
        >
          <MainContainer isAnimationFinished={isFullAnimationFinished} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeContainer;
