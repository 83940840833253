import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

import QuitButton from 'images/quit.png';

const MenuContainer = ({ isVisible, setVisible, onMenuItemClicked }) => {
  const onCloseButtonClicked = () => {
    setVisible(false);
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerAnimation]: isVisible === true,
      })}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      <div
        type="button"
        className={styles.closeButton}
        onClick={() => onCloseButtonClicked()}
      >
        <img src={QuitButton} alt="quit" />
      </div>
      <div className={styles.menuList}>
        <li onClick={() => onMenuItemClicked('presentation')}>Présentation</li>
        <li onClick={() => onMenuItemClicked('help')}>Aide</li>
        <li onClick={() => onMenuItemClicked('thanks')}>Remerciements</li>
        <li onClick={() => onMenuItemClicked('contact')}>Réseaux</li>
      </div>
    </div>
  );
};

export default MenuContainer;
