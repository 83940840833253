import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomeContainer from './HomeContainer';
import DebugContainer from './DebugContainer';
import StatsContainer from './StatsContainer';
import MainContainer from './MainContainer';

import './styles.scss';
import { ColorsContextProvider } from 'context/ColorsContext';

const App = () => {
  return (
    <ColorsContextProvider>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/">
              <HomeContainer />
            </Route>
            <Route exact path="/debug">
              <DebugContainer />
            </Route>
            <Route exact path="/stats">
              <StatsContainer />
            </Route>
            <Route exact path="/generator">
              <MainContainer />
            </Route>
          </Switch>
        </div>
      </Router>
    </ColorsContextProvider>
  );
};

export default App;
