import React, { useState, useEffect } from 'react';

import BarLoader from 'react-spinners/BarLoader';

import styles from './styles.module.scss';

import QuitImage from 'images/quit.png';

const ShareRebusModal = ({
  isOpened,
  setIsOpened,
  image,
  onGifStartDownload,
  onGifEndDownload,
}) => {
  const [isHolding, setIsHolding] = useState(false);

  const closeModal = () => setIsOpened(false);

  const holdGif = (e) => {
    e.stopPropagation();
    setIsHolding(true);
  };

  const stopHoldGif = (e) => {
    e.stopPropagation();
    setIsHolding(false);
  };

  useEffect(() => {
    document.body.style.overflow = isOpened ? 'hidden' : 'unset';
  }, [isOpened]);

  return (
    <div
      className={styles.container}
      style={{ visibility: isOpened ? 'visible' : 'hidden' }}
    >
      <div className={styles.content}>
        <div className={styles.closeButton} onClick={() => closeModal()}>
          <img src={QuitImage} alt="quit" />
        </div>
        <div
          className={styles.imageContainer}
          style={{ pointerEvents: 'none' }}
        >
          <img
            style={{ pointerEvents: 'auto' }}
            onTouchStart={holdGif}
            onTouchCancel={stopHoldGif}
            onTouchEnd={stopHoldGif}
            className={styles.image}
            src={image}
            alt={'rebu'}
          />
        </div>
        {isHolding ? (
          <BarLoader
            height={4}
            width="95%"
            color={'#ffff00'}
            loading={isHolding}
          />
        ) : (
          <div style={{ height: '4px' }}></div>
        )}
        <p className={styles.text}>
          {window.screen.width > 1200
            ? "Effectuez un clic-droit pour copier ou enregistrer l'image"
            : "Appuyez longuement sur l'image pour la copier"}
        </p>
      </div>
    </div>
  );
};

export default ShareRebusModal;
