import React, { useState, useEffect, useContext } from 'react';

import { TextBlockContext } from 'context/TextBlockContext';

import TextBlock from 'components/TextBlock';
import ActionsButtonsContainer from 'components/ActionsButtonsContainer';

import ClickableIcon from 'components/ClickableIcon';

import PresentationBlockText from 'text/presentation.js';
import ThanksBlockText from 'text/thanks.js';
import ContactBlockText from 'text/contact.js';

import styles from './styles.module.scss';

const DesktopRightContainer = ({
  areActionButtonsVisible,
  onDownloadButtonClicked,
}) => {
  const [isTextBlockVisible, setTextBlockVisible] = useState(false);
  const [currentBlockText, setCurrentBlockText] = useState(
    PresentationBlockText
  );

  const { openedTextBlock, setOpenedTextBlock } = useContext(TextBlockContext);

  const [currentTextType, setCurrentTextType] = useState('');

  const [textBlockMarginTop, setTextBlockMarginTop] = useState(0);

  useEffect(() => {
    if (openedTextBlock === 'help' && isTextBlockVisible) {
      setTextBlockVisible(false);
      setCurrentTextType('');
    }
  }, [openedTextBlock]); //eslint-disable-line

  const onMenuIconClicked = (iconType) => {
    if (currentTextType === iconType && isTextBlockVisible)
      setTextBlockVisible(false);
    else {
      switch (iconType) {
        case 'presentation':
          setCurrentBlockText(PresentationBlockText);
          setTextBlockMarginTop(42);
          break;
        case 'thanks':
          setCurrentBlockText(ThanksBlockText);
          setTextBlockMarginTop(42 + 72);
          break;
        case 'contact':
          setCurrentBlockText(ContactBlockText);

          setTextBlockMarginTop(42 + 72 * 2);
          break;
        default:
          break;
      }

      setTextBlockVisible(true);
      setCurrentTextType(iconType);
      setOpenedTextBlock(iconType);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        <div className={styles.menuIcons}>
          <ClickableIcon
            parentFolder={'infos'}
            textType={'presentation'}
            currentTextType={currentTextType}
            onClick={() => onMenuIconClicked('presentation')}
          />
          <ClickableIcon
            parentFolder={'hands'}
            textType={'thanks'}
            currentTextType={currentTextType}
            onClick={() => onMenuIconClicked('thanks')}
          />
          <ClickableIcon
            parentFolder={'plane'}
            textType={'contact'}
            currentTextType={currentTextType}
            onClick={() => onMenuIconClicked('contact')}
          />
        </div>
        <TextBlock
          blockText={currentBlockText}
          isVisible={isTextBlockVisible}
          marginTop={textBlockMarginTop}
        />
      </div>
      <ActionsButtonsContainer
        isVisible={areActionButtonsVisible}
        onDownloadButtonClicked={onDownloadButtonClicked}
      />
    </div>
  );
};

export default DesktopRightContainer;
