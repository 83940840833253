import React from 'react';
import classnames from 'classnames';

import ShareIcon from 'images/icon-share.png';
import DownloadIcon from 'images/icon-download.png';

import styles from './styles.module.scss';

const ActionsButtonsContainer = ({ isVisible, onDownloadButtonClicked }) => {
  const displayShareMessage = () => {
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari']);

    var isFirefox = typeof InstallTrigger !== 'undefined';

    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // console.log(isSafari + ' et ' + isFirefox + ' et ' + isChrome);
    // console.log(window.screen.width);

    if (window.screen.width < 1200) {
      if (isFirefox) {
        return "Téléchargez l'image pour la partager ensuite";
      } else if (isSafari) {
        return "Pour partager, toucher l'image puis la Copier";
      } else if (isChrome) {
        return "Pour partager, toucher l'image";
      } else {
        return "Pour partager, toucher l'image";
      }
    } else {
      return "Pour partager, clic-droit sur l'image et Copier l'Image";
    }
  };

  return (
    <div
      className={styles.container}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      <div className={styles.iconsContainer}>
        <div className={classnames(styles.tooltipButton)}>
          <img
            src={ShareIcon}
            alt="send-icon"
            className={classnames(styles.icon, styles.searchIcon)}
          />
          <span className={styles.shareTooltip}>{displayShareMessage()}</span>
        </div>
        <div
          onClick={() => onDownloadButtonClicked()}
          className={styles.button}
        >
          <img
            src={DownloadIcon}
            alt="search-icon"
            className={classnames(styles.icon, styles.searchIcon)}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionsButtonsContainer;
