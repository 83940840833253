import React, { useState, createContext, useMemo } from 'react';

const TextBlockContext = createContext();

const TextBlockContextProvider = (props) => {
  const [openedTextBlock, setOpenedTextBlock] = useState();

  const contextValues = useMemo(
    () => ({
      openedTextBlock,
      setOpenedTextBlock,
    }),
    [openedTextBlock, setOpenedTextBlock]
  );

  return (
    <TextBlockContext.Provider value={contextValues}>
      {props.children}
    </TextBlockContext.Provider>
  );
};

export { TextBlockContextProvider, TextBlockContext };
