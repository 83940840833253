import React, { useState, useContext } from 'react';

import DesktopLeftContainer from 'components/DesktopLeftContainer';
import DesktopRightContainer from 'components/DesktopRightContainer';

import { ColorsContext } from 'context/ColorsContext';
import { TextBlockContextProvider } from 'context/TextBlockContext';

import GeneratedRebusContainer from 'components/GeneratedRebusContainer';
import InputContainer from 'components/InputContainer';

// Mobile only
import BottomMenuContainer from 'components/BottomMenuContainer';
import MenuContainer from 'components/MenuContainer';
import TextBlockPage from 'components/TextBlockPage';
import ColorPickersMobile from 'components/ColorsPickerMobile/component';
import ShareRebusModal from 'components/ShareRebusModal';

import PresentationBlockText from 'text/presentation.js';
import ThanksBlockText from 'text/thanks.js';
import ContactBlockText from 'text/contact.js';
import HelpBlockText from 'text/help.js';

import TitleImage from 'images/title.png';
import MenuButton from 'images/burger.png';

import styles from './styles.module.scss';

const SERVER_API_ADDRESS = 'https://dorebu-api.online';
const GENERATE_REBUS_URL = '/generate_rebus';

const MainContainer = ({ isAnimationFinished }) => {
  const [inputText, setInputText] = useState('');

  const { strokeColor, backgroundColor } = useContext(ColorsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [base64Image, setBase64Image] = useState(undefined);

  // Mobile only
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isTextBlockPageVisbible, setTextBlockPageVisible] = useState(false);
  const [currentBlockText, setCurrentBlockText] = useState(
    PresentationBlockText
  );
  const [isColorPickersVisible, setColorPickersVisible] = useState(false);
  const [isShareRebusModalVisible, setShareRebusModalVisible] = useState(false);
  const [isBottomMenuVisible, setBottomMenuVisible] = useState(true);

  const generateRebus = async (inputText, strokeColor, backgroundColor) => {
    setIsLoading(true);
    try {
      const response = await fetch(SERVER_API_ADDRESS + GENERATE_REBUS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: inputText,
          strokeColor: strokeColor,
          backgroundColor: backgroundColor,
          is_debug: false,
        }),
      });

      if (response.status === 200) {
        setBase64Image(URL.createObjectURL(await response.blob()));
        setTimeout(() => {
          setIsLoading(false);
        }, 250);
      } else if (response.status === 500) {
        console.log('Erreur : impossible de générer ce rébus');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onGenerateRebusButtonClicked = (inputTextParam) => {
    if (inputText !== '') {
      generateRebus(inputTextParam, strokeColor, backgroundColor);
      setBottomMenuVisible(true);
    }
  };

  const onChangeColor = (strokeColor, backgroundColor) => {
    if (inputText !== '') {
      generateRebus(inputText, strokeColor, backgroundColor);
    }
  };

  const onDownloadButtonClicked = () => {
    fetch(base64Image).then((response) => {
      response.blob().then((blob) => {
        let link = document.createElement('a');
        link.download = `rebus_${Math.floor(Date.now() / 1000)}.png`;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
  };

  // Mobile
  const onColorsButtonClicked = () => {
    setColorPickersVisible(true);
  };

  const onMenuButtonClicked = () => {
    setMenuVisible(true);
  };

  const onGeneratedRebusClicked = () => {
    setShareRebusModalVisible(true);
    setBottomMenuVisible(true);
  };

  const onMenuItemClicked = (menuItem) => {
    setTextBlockPageVisible(true);
    setMenuVisible(false);

    switch (menuItem) {
      case 'presentation':
        setCurrentBlockText(PresentationBlockText);
        break;
      case 'thanks':
        setCurrentBlockText(ThanksBlockText);
        break;
      case 'contact':
        setCurrentBlockText(ContactBlockText);
        break;
      case 'help':
        setCurrentBlockText(HelpBlockText);
        break;
      default:
        break;
    }
  };

  return (
    <TextBlockContextProvider>
      <div className={styles.container}>
        <div className={styles.containerDesktop}>
          <div className={styles.dorebuTitle}>
            <img src={TitleImage} alt="Title" />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.inputContainer}>
              <div className={styles.emptySpace}></div>
              <InputContainer
                onGenerateRebusButtonClicked={onGenerateRebusButtonClicked}
                inputText={inputText}
                setInputText={setInputText}
              />
            </div>
            <div className={styles.pickerImageButtonsContainer}>
              <DesktopLeftContainer onChangeColor={onChangeColor} />
              <GeneratedRebusContainer
                base64Image={base64Image}
                isLoading={isLoading}
                isAnimationFinished={isAnimationFinished}
                backgroundColor={backgroundColor}
                onClick={onGeneratedRebusClicked}
              />
              <DesktopRightContainer
                areActionButtonsVisible={base64Image !== undefined}
                onDownloadButtonClicked={onDownloadButtonClicked}
              />
            </div>
          </div>
          <ShareRebusModal
            isOpened={isShareRebusModalVisible}
            setIsOpened={setShareRebusModalVisible}
            image={base64Image}
          />
        </div>
        <div className={styles.containerMobile}>
          <div className={styles.topMenu}>
            <div className={styles.dorebuTitle}>
              <img src={TitleImage} alt="Title" />
            </div>
            <div
              onClick={() => onMenuButtonClicked()}
              className={styles.menuButton}
            >
              <img src={MenuButton} alt="menu" />
            </div>
          </div>
          <InputContainer
            onGenerateRebusButtonClicked={onGenerateRebusButtonClicked}
            inputText={inputText}
            setInputText={setInputText}
            onFocusStart={() => setBottomMenuVisible(false)}
            onFocusEnd={() => setBottomMenuVisible(true)}
          />
          <GeneratedRebusContainer
            base64Image={base64Image}
            isLoading={isLoading}
            isAnimationFinished={isAnimationFinished}
            backgroundColor={backgroundColor}
            onClick={onGeneratedRebusClicked}
          />
          <div style={{ height: '128px' }}></div>
          <BottomMenuContainer
            isVisible={
              isBottomMenuVisible && !isMenuVisible && !isTextBlockPageVisbible
            }
            areActionButtonsVisible={base64Image !== undefined}
            onDownloadButtonClicked={onDownloadButtonClicked}
            onColorsButtonClicked={onColorsButtonClicked}
          />
        </div>
        <MenuContainer
          isVisible={isMenuVisible}
          isHomeAnimationFinished={isAnimationFinished}
          setVisible={setMenuVisible}
          onMenuItemClicked={onMenuItemClicked}
        />
        <TextBlockPage
          blockText={currentBlockText}
          isVisible={isTextBlockPageVisbible}
          setVisible={setTextBlockPageVisible}
        />
        <ColorPickersMobile
          isVisible={isColorPickersVisible}
          setVisible={setColorPickersVisible}
          onChange={onChangeColor}
        />
        <ShareRebusModal
          isOpened={isShareRebusModalVisible}
          setIsOpened={setShareRebusModalVisible}
          image={base64Image}
        />
      </div>
    </TextBlockContextProvider>
  );
};

export default MainContainer;
