const text = `<p>DORÉBU est un générateur automatique de Rébus. Lorsque que vous entrez votre texte, celui-ci se transforme en une série d’images dessinées. 
C’est une rencontre entre les arts de l’écriture, de la parole, de l’illustration, des devinettes et de l’informatique.</p>
<p>Le concept et les quelque 250 illustrations ont été réalisés par <a href="https://www.studionanana.com">Mona Nahas</a>.<p/>
<p>Cette fabrique à Rébus reste avant tout un jeu, elle peut servir à envoyer des messages codés, créer des énigmes et amuser vos proches. </p>
<p>Les rébus générés par DORÉBU ne doivent pas être utilisés à des fins commerciales ou professionnelles sans mon accord préalable.</p>
`;

const title = 'PRÉSENTATION';

export default { text, title };
